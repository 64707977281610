import { styled } from '@/styles/stitches';

export const ModalForm = styled('form', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  gap: '$8',

  div: {
    marginBottom: '0px',
    width: '100%',

    strong: {
      color: 'red',
      marginTop: '$2',
      display: 'block',
    },
  },

  'input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill':
    {
      '-webkit-box-shadow': '0 0 0 1000px rgb(44, 14, 128) inset !important',
      '-webkit-text-fill-color': 'white !important',
    },
});

export const ModalFormFooter = styled('div', {
  display: 'flex',
  gap: '$3',
  width: '100%',

  '@media (max-width: 920px)': {
    flexDirection: 'column',
  },

  button: {
    width: '100%',
    height: '2.625rem',
    borderRadius: '$md',
    border: '2px solid $primaryHover',
    background: '$brightColour',
    fontWeight: '$bold',
    transition: 'color 0.2s ease-in',
    position: 'relative',
    '&:hover': {
      color: '#AEA0E8',
    },
  },

  variants: {
    isLoading: {
      true: {
        button: {
          cursor: 'not-allowed',
          '&:hover': {
            color: '$texts',
          },
        },
      },
    },
  },
});

export const InputGroup = styled('div', {
  borderBottom: '1px solid $brightColour',
  paddingBottom: '$1',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  position: 'relative',
  width: '100%',

  svg: {
    position: 'absolute',
    left: 0,
    zIndex: 99,
  },

  input: {
    height: '1.75rem',
    zIndex: 3,
    width: '100%',
    paddingLeft: '$11',
    background: 'transparent',
    border: 0,
    outline: '0',
    color: '$brightColour',
    fontSize: '$md',
    '&:disabled': {
      cursor: 'not-allowed',
    },
    '&::placeholder': {
      color: '$brightColour',
      fontSize: '$md',
    },
  },
});
