import Select from 'react-select';

import { styled, keyframes } from '../stitches';

const fadeInSignIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const slideIn = keyframes({
  '0%': { transform: 'translateX(100%)', opacity: 0 },
  '100%': { transform: 'translateX(0%)', opacity: 1 },
});

export const Container = styled('div', {
  position: 'relative',
  width: '100%',
  background:
    'linear-gradient(178.86deg, $primary 10.17%, rgba(69, 33, 171, 0.92) 27.44%, rgba(69, 33, 171, 0.88) 43.28%, rgba(69, 33, 171, 0.85421) 61.51%, rgba(69, 33, 171, 0.824252) 78.31%, rgba(69, 33, 171, 0.8) 90.78%, rgba(69, 33, 171, 0.74) 99.02%);',
  display: 'flex',
  minHeight: '100vh',
});

export const Wrapper = styled('div', {
  maxWidth: '90rem',
  width: '100%',
  margin: '0 auto',
  display: 'flex',

  minHeight: '100vh',
  padding: '$5',
  animation: `${fadeInSignIn} ease-in 0.5s`,
});

export const NextouContainer = styled('div', {
  width: '50%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '0.625rem',

  padding: '3.5rem $12',

  position: 'relative',
  background: '$brightColour',

  'img#logo': {
    width: '8.2625rem',
    height: '2.5656rem',
    position: 'absolute',
    top: '3.5rem',
    left: '2.75rem',
  },

  'img#nextou': {
    maxWidth: '34.5625rem',
    height: '34.8125rem',
    width: '100%',
  },

  p: {
    color: '$texts',
    fontSize: '$xl',
    fontWeight: '$medium',
    lineHeight: '30px',
    maxWidth: '28.4375rem',
    width: '100%',
  },

  '@media (max-width: 920px)': {
    display: 'none',
  },
});

export const LoginContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  width: '50%',

  borderTopRightRadius: '0.625rem',
  borderBottomRightRadius: '0.625rem',

  '@media (max-width: 920px)': {
    minHeight: '100vh',
    width: '100%',
    padding: '0px 1rem',
  },
});

export const LoginWrapper = styled(`div`, {
  maxWidth: '21.4375rem',
  width: '100%',
  margin: '0 auto',

  p: {
    fontWeight: '$regular',
    fontSize: '$md',
    color: '$brightColour',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '8px',
      right: '8px',
      width: '0',
      height: '0',
      borderTop: '5px solid $primary',
      borderRight: '5px solid transparent',
      borderLeft: '5px solid transparent',
      pointerEvents: 'none', // Impede que o elemento pseudo seja clicável
    },

    a: {
      margin: '0',
    },
  },

  a: {
    marginTop: '$5',
    display: 'block',
    color: '$brightColour',
    // display: 'flex',
  },
});

export const LoginSelect = styled(Select, {
  marginTop: '$9',
  animation: `${slideIn} 0.3s ease-in`,
});

export const LoginHeader = styled('div', {
  display: 'flex',
  gap: '1.4375rem',
  alignItems: 'flex-start',

  h1: {
    fontSize: '3.375rem',
    fontWeight: '$bold',
    lineHeight: '81px',
    color: '$brightColour',
  },
});

export const LoginOptions = styled('ul', {
  listStyle: 'none',
  marginTop: '$9',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  gap: '$3',
  li: {
    width: 'inherit',
    height: '3.125rem',
    button: {
      background: 'transparent',
      display: 'flex',
      width: '100%',
      height: '3.125rem',
      alignItems: 'center',
      gap: '$3',
      border: '1px solid $brightColour',
      borderRadius: '$sm',

      transition: 'color 0.2s ease-in',

      '&:hover': {
        strong: {
          color: '$primaryHover',
        },
      },

      div: {
        width: '5.3125rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        background: '$primaryHover',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },

      strong: {
        color: '$brightColour',
      },
    },
  },
});

export const AboutNextou = styled('p', {
  marginTop: '$4',
  fontSize: '$md',
  fontFamily: '$default',
  a: {
    fontFamily: 'inherit',
    fontSize: '$md',
    textDecoration: 'none',

    color: '$brightColour',
    transition: 'color 0.2s ease-in',

    span: {
      '&:hover': {
        color: '$primaryHover',
      },
    },
  },
});
