import Modal from 'react-modal';
import * as S from '../styles';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { toast } from 'react-toastify';
import jwt from 'jwt-decode';

import { Auth } from 'aws-amplify';
import { ToastIcon } from '@/components/global/ToastIcon';
import { FiUser } from 'react-icons/fi';
import { RiLockPasswordFill } from 'react-icons/ri';

type LoginFormData = {
  username: string;
  password: string;
};

type Token = {
  sub: string;
  'cognito:groups': string[];
};

type ModalWithUsernameProps = {
  onClose: () => void;
};

const LoginFormValidation = yup.object().shape({
  username: yup.string().required('Informe seu nome de usuário'),
  password: yup.string().required('Informe sua senha'),
});

export function ModalWithUsername({ onClose }: ModalWithUsernameProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormValidation),
  });

  async function submit({ password, username }: LoginFormData) {
    try {
      setIsLoading(true);

      const response = await Auth.signIn({
        password,
        username,
      });

      const token: Token = jwt(response.signInUserSession.idToken.jwtToken);

      const group = token['cognito:groups'][0];

      if (group !== 'admin') {
        throw new Error('Just Admin can be access');
      }

      router.push('/escolas');
    } catch (err) {
      toast.error(<strong>Usuário ou senha inválido</strong>, {
        icon: <ToastIcon type="error" />,
      });
      setIsLoading(false);
    }
  }

  return (
    <Modal
      isOpen={true}
      contentLabel="Login With Username"
      style={{
        content: {
          background: '#2C0E80',
          border: '1px solid #AEA0E8',
          maxWidth: '450px',
          height: '300px',
          width: '100%',
        },
      }}
    >
      <S.ModalForm onSubmit={handleSubmit(submit)}>
        <div>
          <S.InputGroup>
            <FiUser color="#fff" size={30} />
            <input
              type="text"
              placeholder="Nome De Usuário"
              {...register('username')}
            />
          </S.InputGroup>
          {!!errors.username?.message && (
            <strong>{errors.username.message}</strong>
          )}
        </div>
        <div>
          <S.InputGroup>
            <RiLockPasswordFill size={30} color="#fff" />
            <input
              type="password"
              {...register('password')}
              disabled={isLoading}
              placeholder="Digite sua senha"
            />
          </S.InputGroup>
          {!!errors.password?.message && (
            <strong>{errors.password.message}</strong>
          )}
        </div>
        <S.ModalFormFooter isLoading={isLoading}>
          {!isLoading && (
            <button onClick={onClose} type="button">
              Cancelar
            </button>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Carregando' : 'Entrar'}
          </button>
        </S.ModalFormFooter>
      </S.ModalForm>
    </Modal>
  );
}
