import { ToastIconContainer } from './styles';

type ToastIconProps = {
  type: 'success' | 'error';
};

export function ToastIcon({ type }: ToastIconProps) {
  let Icon = null;

  switch (type) {
    case 'success':
      Icon = (
        <ToastIconContainer
          src="/icons/confirm.svg"
          alt="Ícone de confirmação"
        />
      );
      break;
    case 'error':
      Icon = (
        <ToastIconContainer src="/icons/error.svg" alt="Ícone de confirmação" />
      );
      break;

    default:
      Icon = (
        <ToastIconContainer
          src="/icons/confirm.svg"
          alt="Ícone de confirmação"
        />
      );
      break;
  }

  return Icon;
}
