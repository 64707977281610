import * as S from '@/styles/pages/SignIn';
import { getSession } from '@/utils/getSession';
import { GetServerSideProps, NextApiRequest } from 'next';

import { useCallback, useState } from 'react';

import Head from 'next/head';
import Image from 'next/future/image';

import { MdAlternateEmail } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import Link from 'next/link';
import { ModalWithEmail } from '@/components/pages/Login/WithEmail';
import { ModalWithUsername } from '@/components/pages/Login/WithUsername';

type LoginWith = 'email' | 'username' | string;

export default function Login() {
  const [loginWith, setLoginWith] = useState<LoginWith>('');

  function handleOpenModal(type: LoginWith) {
    setLoginWith(type);
  }

  const handleCloseModal = useCallback(() => {
    setLoginWith('');
  }, []);

  return (
    <S.Container>
      {loginWith === 'email' && <ModalWithEmail onClose={handleCloseModal} />}
      {loginWith === 'username' && (
        <ModalWithUsername onClose={handleCloseModal} />
      )}
      <Head>
        <title>Nextou - Login</title>
      </Head>
      <S.Wrapper>
        <S.NextouContainer>
          <Image
            alt="Logo"
            src="/logo.svg"
            width={137}
            height={43.93}
            id="logo"
          />
          <Image
            width={553}
            height={557}
            src="/nextouLogin.svg"
            alt="Nextou"
            id="nextou"
          />

          <p>
            Faça com que o aprendizado fique ao alcance de todos. Comece agora
            mesmo!
          </p>
        </S.NextouContainer>
        <S.LoginContainer>
          <S.LoginWrapper>
            <S.LoginHeader>
              <h1>Login</h1>
              <Image
                src="/Nestor.svg"
                alt="Nestor"
                width={54.94}
                height={67.73}
              />
            </S.LoginHeader>

            <S.LoginOptions>
              <li>
                <button onClick={() => handleOpenModal('email')}>
                  <div>
                    <MdAlternateEmail size={20} color="#fff" />
                  </div>
                  <strong>Entrar com E-Mail</strong>
                </button>
              </li>
              <li>
                <button onClick={() => handleOpenModal('username')}>
                  <div>
                    <FaUser size={20} color="#fff" />
                  </div>
                  <strong> Entrar com nome Usuário</strong>
                </button>
              </li>
            </S.LoginOptions>
            <Link prefetch={false} href="/esqueci-minha-senha">
              Esqueceu sua senha?
            </Link>
            <S.AboutNextou>
              <Link passHref href="https://nextou.com.br/">
                <a target="_blank">
                  Ainda não conhece o Nextou? <span>Saiba Mais</span>
                </a>
              </Link>
            </S.AboutNextou>
          </S.LoginWrapper>
        </S.LoginContainer>
      </S.Wrapper>
    </S.Container>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  req: apiReq,
}) => {
  try {
    const req = apiReq as NextApiRequest;

    await getSession(req);

    return {
      props: {},
      redirect: {
        destination: '/escolas',
        permanent: false,
      },
    };
  } catch (err) {
    return {
      props: {},
    };
  }
};
